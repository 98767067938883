<template>
  <div id="app">
    <div class="title">{{title}}</div>
    <div class="content">
      <div>尊敬的客户：</div>
      <div class="main">{{content}}</div>
      <div class="date">{{date}}</div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'App',
  data() {
    return {
      title: '',
      content: '',
      date: ''
    }
  },
  mounted() {
    this.title = window._CONFIG['title'] || ''
    this.content = window._CONFIG['content'] || ''
    this.date = window._CONFIG['date'] || ''
  }
}
</script>

<style scoped>
html, body {
  width: 100%;
  height: 100%;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  width: 100%;
  height: 100%;
}
.title {
  border-bottom: 1px solid #e9e9e9;
  padding-bottom: 20px;
  padding-top: 40px;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  width: 100%;
}
.content {
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
}
.content .main {
  text-indent: 28px;
  margin: 20px 0;
  line-height: 30px;
}
.content .date {
  text-align: right;
}
</style>
